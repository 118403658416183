import React from "react";
import { PositionIndicatorContainer } from "./style";
import padLeadingZero from "~/utils/padLeadingZero";

interface PositionIndicatorProps {
  position: number;
  length: number;
  className?: string;
}

export default function PositionIndicator({ position, length, ...props }: PositionIndicatorProps) {
  return (
    <PositionIndicatorContainer {...props}>
      <span>{padLeadingZero((position || 0) + 1)}</span>
      <span>{`/ ${padLeadingZero(length)}`}</span>
    </PositionIndicatorContainer>
  );
}
