import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { transparentize } from "polished";
import { motion } from "framer-motion";
import Chip from "~/components/Chip";
import BaseGrid from "~/components/GridLayout";

export const SlideContentContainer = styled(motion.div).attrs({
  initial: "initial",
  animate: "animate",
  exit: "exit",
})`
  width: 100%;
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8rem;
`;

export const FooterGridContent = styled(motion(BaseGrid)).attrs({
  variants: {
    exit: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    initial: {
      opacity: 0,
    },
  },
})`
  ${({ theme }) => css`
    height: 100%;
    & > * {
      align-self: center;
    }
    & > *:first-child {
      grid-column-end: span 3;

      @media (max-width: 800px) {
        grid-column: 1 / span 5;
      }
    }
    & > *:nth-child(2) {
      grid-column-end: span 4;

      @media (max-width: 800px) {
        grid-column: 6 / span 5;
      }
    }

    label {
      margin-bottom: 1.5em;
    }

    p  {
      color: ${theme.palette.onBackground.shade1};
    }
  `}
`;

export const StyledGrid = styled(BaseGrid)`
  ${({ theme }) => css`
    grid-auto-rows: 1fr auto;

    & > * {
      grid-column: 1 / span 5;

      @media (max-width: 800px) {
        grid-column: 1 / span 6;
      }

      @media (max-width: 576px) {
        grid-column: 1 / span 11;
      }
    }

    h1,
    h5 {
      margin: 0 0 1.75rem 0;
    }

    h5 {
      color: ${theme.palette.onBackground.shade1};
      em {
        color: ${theme.palette.onBackground.main};
      }
    }
  `}
`;

export const FixedContentsGrid = styled(BaseGrid)`
  ${({ theme }) => css`
    h5 {
      pointer-events: none;
      margin-bottom: 16rem;
      align-self: center;
      color: ${theme.palette.onBackground.shade1};
      grid-column: 1 / span 5;

      @media (max-width: 800px) {
        grid-column: 1 / span 10;
      }
      em {
        color: ${theme.palette.onBackground.main};
      }
    }
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 2;
    transform: translateX(-50%);
    pointer-events: none;
    margin: unset;
  `}
`;

export const TagsContainer = styled(motion.ul).attrs({
  variants: {
    animate: {
      transition: { staggerChildren: 0.07, delayChildren: 0.5 },
    },
    exit: {
      transition: {
        staggerChildren: 0.04,
        staggerDirection: -1,
      },
    },
  },
})`
  grid-column: 1 / span 7;
  margin: -0.5em;
  padding: 0;

  @media (max-width: 890px) {
    grid-column: 1 / span 5;
  }

  @media (max-width: 800px) {
    grid-column: 1 / span 9;
  }
`;

export const AnimatedChip = styled(motion(Chip)).attrs({
  variants: {
    exit: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    initial: {
      opacity: 0,
    },
  },
})`
  margin: 0.5em;
`;

export const AnimatedText = styled(motion.h1).attrs({
  variants: {
    exit: {
      y: "-100%",
      opacity: 0,
      transition: {
        ease: [0.77, 0, 0.175, 1],
      },
    },
    animate: {
      y: 0,
      opacity: 1,
    },
    initial: {
      y: "100%",
      opacity: 0,
      transition: {
        ease: [0.77, 0, 0.175, 1],
      },
    },
  },
  transition: {
    duration: 0.75,
    delay: 0.3,
  },
})`
  display: block;
  padding-bottom: 6px;
  will-change: transform;
`;

export const AnimatedTextContainer = styled.div`
  display: inline-block;
  margin-bottom: -6px;
  overflow: hidden;
  position: relative;
  vertical-align: center;
  white-space: pre-wrap;
`;

export const PrototypeContainer = styled(motion.div).attrs({
  variants: {
    initial: {
      scale: 0.65,
      opacity: 0,
    },
    animate: {
      scale: 1,
      opacity: 1,

      transition: {
        type: "spring",
        bounce: 0.25,
      },
    },
    exit: {
      scale: 0.8,
      opacity: 0,
    },
  },
})`
  ${({ theme }) => css`
    border-radius: 50%;
    width: 40vw;
    height: 40vw;
    min-width: 50vh;
    min-height: 50vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 4%;
    z-index: 1;
    background: ${transparentize(0.3, theme.palette.accent)};
    box-shadow: 0 0 0 3rem ${transparentize(0.7, theme.palette.accent)};
  `}
`;

export const PrototypeImg = styled(motion.img).attrs({
  variants: {
    initial: {
      y: 10,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.75,
      },
    },
    exit: {
      y: 10,
      opacity: 0,
    },
  },
})`
  max-height: 110%;
  max-width: 110%;
`;

export const LinkContainer = styled(Link)`
  color: inherit;
`;
