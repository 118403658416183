import styled, { css } from "styled-components";

export const Container = styled.footer`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    background: ${theme.palette.overlayBackground};

    &:before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      pointer-events: none;
      height: 1000px;
      background-image: radial-gradient(
        ellipse at center bottom,
        rgb(0 56 132 / 7%),
        transparent 70%
      );
    }
  `}
`;

export const FooterContent = styled.div`
  ${({ theme }) => css`
    height: 7rem;
    display: flex;
    color: ${theme.palette.onBackground.shade2};
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
    ${theme.dimensions.mixins.centeredBody.static}
  `}
`;
