import { motion } from "framer-motion";
import styled from "styled-components";
import usePortal from "react-useportal";
import { MENU_MODAL_ID } from "~/globals";

export default function useModal() {
  const portal = usePortal({
    bindTo: document ? document.getElementById(MENU_MODAL_ID) || undefined : undefined,
    closeOnOutsideClick: false,
  });

  const AnimatedPortal = styled(motion(portal.Portal)).attrs({
    initial: "initial",
    animate: "animate",
    exit: "exit",
  });

  return { Portal: AnimatedPortal, ...portal };
}
