import palette from "./colors";
import mediaqueries, { breakpoints } from "./media";
import dimensions from "./dimensions";
import { globalMixins } from "./mixins";
import easings from "./easings";
// Typography
import "typeface-inter";
import "typeface-fira-code";

const theme = {
  palette,
  breakpoints,
  media: mediaqueries,
  shadows: {
    sm: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px, rgba(0, 0, 0, 0.08) 0px 2px 10px 0px",
    md: "rgba(0, 0, 0, 0.08) 0px 1px 4px 0px, rgba(0, 0, 0, 0.06) 0px 3px 16px 0px",
    lg: "0 30px 60px -10px rgba(0, 0, 0, 0.22), 0 18px 36px -18px rgba(0, 0, 0, 0.25)",
    xl: "0 30px 60px -10px rgba(0, 0, 0, 0.3), 0 18px 36px -18px rgba(0, 0, 0, 0.33)",
    xxl: "0 50px 80px -20px rgba(0, 0, 0, 0.27), 0 30px 50px -30px rgba(0, 0, 0, 0.3)",
  },
  dimensions,
  easings,
  mixins: globalMixins,
};

export default theme;
export { GlobalStyles } from "./globalStyles";
