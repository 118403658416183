import React from "react";
import { Section, SectionRoute } from "~/types/Section";
import * as Sections from "./";

type SectionProperties = Omit<Section, "children"> & {
  Child: (props: SectionRoute) => JSX.Element;
};

const sectionRouteProperties: SectionProperties[] = [
  {
    href: "home",
    scrollIndex: 0,
    linkText: "Home",
    Child: Sections.Welcome,
  },
  {
    href: "projects",
    scrollIndex: 1,
    linkText: "Projects",
    Child: Sections.Projects,
  },
  {
    href: "tech",
    scrollIndex: 2,
    linkText: "My preferred technologies",
    Child: Sections.Technologies,
  },
  {
    href: "about",
    scrollIndex: 3,
    linkText: "About me",
    Child: Sections.About,
  },
];

const sectionRoutes: Section[] = sectionRouteProperties.map(({ Child, ...route }) => ({
  children: <Child {...route} />,
  ...route,
}));

export default sectionRoutes;
