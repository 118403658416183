import { css } from "styled-components";

const toEm = (size: number) => size / 16 + "em";

const fluidTypeConfig = {
  minViewportLock: 1024,
  maxViewportLock: 1920,
  minPaddingLock: 400,
  maxPaddingLock: 1200,
};

type Breakpoint = [string, number];

const bps: Breakpoint[] = [
  ["phone_small", 320],
  ["phone", 376],
  ["phablet", 540],
  ["tablet", 735],
  ["desktop", 1070],
  ["desktop_medium", 1280],
  ["desktop_large", 1440],
];

/**
 * All breakpoints can be found inside of theme.breakpoints.
 * Each is turned in to a min + 1 and max-width version.
 *
 * There are also break points to cover coarse and fine pointer devices
 *
 * @example
 *
 *    ${mediaqueries.phone` width: 100px; `};
 *    ${mediaqueries.tablet_up` width: 200px; `};
 */

const mediaqueries = bps.reduce(
  (acc, [label, size], i) => ({
    ...acc,
    // max-width media query e.g. mediaqueries.desktop
    [label]: (...args: any[]) => css`
      @media (max-width: ${toEm(size)}) {
        ${/* @ts-ignore */
        css(...args)};
      }
    `,
    // min-width media query e.g. mediaqueries.desktop_up
    // This is the breakpoint prior's size +1
    [`${label}_up`]: (...args: any[]) => css`
      @media (min-width: ${toEm(bps[i - 1][1] + 1)}) {
        ${/* @ts-ignore */
        css(...args)};
      }
    `,
  }),
  {},
);

export const breakpoints = bps.reduce(
  (acc, [label, size]) => ({
    ...acc,
    [label]: size,
  }),
  fluidTypeConfig,
);

export default mediaqueries;
