import React, { useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Typed from "typed.js";
import { AnimatePresence } from "framer-motion";
import { BackgroundContainer, TextContent, BackgroundImage, TypedWrapper } from "./style";
import { useScrollContext } from "~/contexts/ScrollContext";
import OverflownHeadline from "~/components/OverflownHeadline";
import { SectionRoute } from "~/types/Section";

export default function Welcome({ scrollIndex: sectionScrollIndex }: SectionRoute) {
  const { isFullpageLoaded, scrollIndex } = useScrollContext();
  const inView = isFullpageLoaded && scrollIndex === sectionScrollIndex;

  const typedRef = useRef<Typed | null>(null);
  const typedEl = useRef<HTMLElement | null>(null);

  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "welcome.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1920, srcSetBreakpoints: [1600, 2800, 3020], quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (typedEl.current && inView) {
      const trailingDot = document.createTextNode(".");
      // await entering animation
      setTimeout(() => {
        typedEl.current?.parentElement?.appendChild(trailingDot);
        if (typedEl.current) {
          typedRef.current = new Typed(typedEl.current as any, {
            strings: ["", "meaningful", "performant", "dry", "user centered", "efficient"],
            loop: true,
            typeSpeed: 40,
            backSpeed: 20,
            backDelay: 1200,
          });
        }
      }, 2000);
    }

    return () => {
      typedRef.current && typedRef.current.destroy();
    };
  }, [inView]);

  return (
    <BackgroundContainer>
      {/* @ts-ignore */}
      <BackgroundImage fluid={image.childImageSharp.fluid} />
      <AnimatePresence>
        {inView && (
          <TextContent>
            <OverflownHeadline key="welcome" as="h5" highlighted={["Tim", "Weise"]} inline>
              Welcome to the Portfolio of Tim Weise
            </OverflownHeadline>
            <OverflownHeadline
              style={{ margin: "0.1em 0" }}
              as="h1"
              highlighted={["designing", "developing"]}>
              I am passionate about designing and developing software that is
            </OverflownHeadline>
            <TypedWrapper>
              <em ref={typedEl}>&nbsp;</em>
            </TypedWrapper>
          </TextContent>
        )}
      </AnimatePresence>
    </BackgroundContainer>
  );
}
