import styled, { css } from "styled-components";
import { tint } from "polished";

export const SquareGrid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${theme.dimensions.gridGap};
  `}
`;

export const activeStyles = css`
  ${({ theme }) => `
    color: ${theme.palette.onBackground.main} !important;
    box-shadow: ${theme.shadows.md};
    background: ${theme.palette.accent};
    transform: scale(1.04);
  `}
`;

export const inactiveStyles = css`
  ${({ theme }) => `
    color: ${theme.palette.onBackground.card.opaque.shade1};
    box-shadow: ${theme.shadows.sm};
    background: ${theme.palette.card.opaque};
  `}
`;

export const SquareGridItem = styled.div<{ active: boolean }>`
  ${({ active, theme }) => css`
    cursor: pointer;
    padding-bottom: 100%;
    position: relative;
    display: flex;
    border-radius: 0.75rem;
    justify-content: center;
    align-items: center;
    height: 0;
    transition: color 0.3s ease, box-shadow 0.3s ease;
    ${active ? activeStyles : inactiveStyles}

    &:hover {
      color: ${tint(0.7, theme.palette.onBackground.shade2)};
    }

    & > * {
      position: absolute !important;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
      width: 40% !important;
      height: 40% !important;
    }
  `}
`;
