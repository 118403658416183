import styled, { css } from "styled-components";

export const BaseGrid = styled.div`
  ${({ theme }) => css`
    display: grid;
    column-gap: ${theme.dimensions.gridGap};
    grid-template-columns: repeat(12, 1fr);
    width: 100%;
    box-sizing: border-box;
    ${theme.dimensions.mixins.centeredBody.static}
  `}
`;
