import React, { ReactNode } from "react";
import Helmet from "react-helmet";
import { ThemeProvider } from "styled-components";
import theme, { GlobalStyles } from "~/theme";

export default function GlobalProviders({ children }: { children: ReactNode }) {
  return (
    <>
      <Helmet>
        <link
          rel="apple-touch-icon-precomposed"
          sizes="57x57"
          href="/assets/favicon-apple-touch-114.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href="/assets/favicon-apple-touch-114.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="72x72"
          href="/assets/favicon-apple-touch-144.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="144x144"
          href="/assets/favicon-apple-touch-144.png"
        />
        <link
          rel="icon"
          type="image/vnd.microsoft.icon"
          sizes="32x32 48x48"
          href="/assets/favicon.ico"
        />
        <link rel="icon" sizes="128x128" href="/assets/favicon.icns" />
        <link rel="icon" href="/assets/favicon.png" type="image/x-icon" />
      </Helmet>

      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </>
  );
}
