import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import useProjectsQuery from "~/queries/useProjectsQuery";
import OverflownHeadline from "~/components/OverflownHeadline";
import { useScrollContext } from "~/contexts/ScrollContext";
import Slideshow, { Slide, useSlideshowScrolling } from "~/components/Slideshow";
import { IProject } from "~/types/Project";
import { SectionRoute } from "~/types/Section";
import { FixedContentsGrid } from "./style";
import AnimatedSlideContent from "./AnimatedSlideContent";
import { ArrowCursorLink } from "~/components/Link";

export default function Projects({ scrollIndex: sectionScrollIndex }: SectionRoute) {
  const {
    allProject: { nodes },
  } = useProjectsQuery();
  const { isFullpageLoaded, scrollIndex } = useScrollContext();
  const inView = isFullpageLoaded && scrollIndex === sectionScrollIndex;
  const projects: IProject[] = nodes;
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const numberOfSlides = projects.length;

  useSlideshowScrolling(sectionScrollIndex, currentSlide, numberOfSlides, setCurrentSlide);
  return (
    <>
      <FixedContentsGrid>
        <AnimatePresence>
          {inView && (
            <OverflownHeadline key="projects" as="h5" highlighted={["projects"]}>
              Have a look at some of my projects
            </OverflownHeadline>
          )}
        </AnimatePresence>
      </FixedContentsGrid>
      <Slideshow
        activeSlideIndex={currentSlide}
        onNextSlideClick={() =>
          setCurrentSlide(slide => (slide + 1 > numberOfSlides ? slide : slide + 1))
        }>
        {projects.map((project, i) => (
          <ArrowCursorLink key={project.name}>
            <Slide bgImg={project.bgImgSrc.childImageSharp.fluid} href={project.slug}>
              <AnimatedSlideContent visible={currentSlide === i} project={project} />
            </Slide>
          </ArrowCursorLink>
        ))}
      </Slideshow>
    </>
  );
}
