import styled, { css } from "styled-components";
import { motion } from "framer-motion";

const iconGap = 1.75; // rem

const blurryHeaderMixin = css`
  background: rgb(0 0 0 / 5%); // TODO: Make this a theme color
  border-bottom: 1px solid ${p => p.theme.palette.onBackground.shade2};
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(6px);
  }
`;

export const HeaderContainer = styled.header<{ isFixed: boolean; menuOpen: boolean }>`
  position: ${p => (p.isFixed ? "fixed" : "relative")};
  top: 0;
  width: 100%;
  z-index: 21;
  transition: background 0.4s ease;
  ${p => !p.isFixed && !p.menuOpen && blurryHeaderMixin}
  ${p => p.theme.mixins.roundedMobileEdgesMixin}
  ${p => p.theme.media.phablet`backdrop-filter: blur(6px);`}
`;

export const HeaderInnerContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 2rem;
    padding-bottom: 1rem;
    ${theme.dimensions.mixins.centeredBody.static}
    ${theme.dimensions.mixins.bodyPadding.vertical}
  `}
`;

export const RightContainer = styled.div`
  margin-right: ${-1 * (iconGap / 2)}rem;

  & > * {
    margin: 0 ${iconGap / 2}rem;
  }
`;

export const TwLogoContainer = styled.div`
  box-sizing: border-box;
  height: 0.75rem;
  position: relative;

  svg {
    height: 100%;
  }
`;

export const HeadroomContainer = styled(motion.div).attrs({
  initial: {
    marginTop: -150,
  },
  animate: {
    marginTop: 0,
  },
  exit: {
    marginTop: -150,
  },
  transition: {
    delay: 0.3,
  },
})``;
