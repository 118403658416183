import React, { useCallback } from "react";
import { AnimatePresence } from "framer-motion";
import Headroom from "react-headroom";
import MenuIcon from "../MenuIcon";
import IconButton from "../IconButton";
import {
  HeaderContainer,
  RightContainer,
  HeaderInnerContainer,
  TwLogoContainer,
  HeadroomContainer,
} from "./style";
import useModal from "~/utils/useModal";
import pageRoutes from "~/sections/Home/sectionRoutes";
import MenuOverlay from "~/components/MenuOverlay";
import TwLogo from "~/components/TwLogo";

interface HeaderProps {
  isSticky?: boolean;
}

export default function Header({ isSticky }: HeaderProps) {
  const { openPortal, closePortal, isOpen, Portal } = useModal();

  const handleMenuIconClick = useCallback(
    e => {
      isOpen ? closePortal(e) : openPortal(e);
    },
    [isOpen, openPortal, closePortal],
  );
  const innerJsx = (
    <>
      <HeaderContainer menuOpen={isOpen} isFixed={!isSticky}>
        <HeaderInnerContainer>
          <div>
            <MenuIcon onClick={handleMenuIconClick} active={isOpen} />
          </div>
          <TwLogoContainer>
            <TwLogo isShown={!isOpen && isSticky} animateOnHover fillAll />
          </TwLogoContainer>
          <RightContainer>
            <IconButton href="https://www.xing.com/profile/Tim_Weise13/cv" icon="xing" />
            <IconButton href="https://github.com/switchnollie/" icon="github" />
          </RightContainer>
        </HeaderInnerContainer>
      </HeaderContainer>
      <AnimatePresence>
        {isOpen && (
          <Portal key="portal">
            <MenuOverlay
              routeLinks={pageRoutes.map(r => ({ name: r.linkText, href: `#${r.href}` }))}
              closePortal={closePortal}
            />
          </Portal>
        )}
      </AnimatePresence>
    </>
  );

  return (
    <>
      <AnimatePresence>
        {isSticky && (
          <HeadroomContainer key="container">
            <Headroom style={{ zIndex: 21 }}>{innerJsx}</Headroom>
          </HeadroomContainer>
        )}
      </AnimatePresence>
      {!isSticky && innerJsx}
    </>
  );
}
