import { motion } from "framer-motion";
import React from "react";

export default function Arrow({ isOverLink }: { isOverLink: boolean }) {
  return (
    <motion.g
      animate={isOverLink ? { opacity: 1, x: 0 } : { opacity: 0, x: "-10%" }}
      initial={{ opacity: 0, x: "-10%" }}
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g>
        <g transform="translate(23.000000, 25.978942)" fill="#FFFFFF">
          <rect x="0" y="22.0210577" width="52" height="4"></rect>
          <polygon
            fillRule="nonzero"
            points="29.849 0 53.8700577 24.0210577 29.85 48.042 27.02 45.213 48.2132034 24.0196435 27.022 2.827"></polygon>
        </g>
        <rect x="0" y="0" width="100" height="100"></rect>
      </g>
    </motion.g>
  );
}
