import { motion } from "framer-motion";
import { transparentize } from "polished";
import styled, { createGlobalStyle } from "styled-components";
import theme from "~/theme";

export const AnimatedCursor = styled(motion.svg)`
  position: absolute;
  width: 2em;
  margin-left: -1em;
  margin-top: -1em;
  height: 2em;
  background: none;
  pointer-events: none;
  z-index: 999999;
  overflow: visible;
`;

export const Circle = styled(motion.circle).attrs(({ isOverLink }: any) => ({
  animate: isOverLink
    ? {
        scale: 1.3,
        stroke: "rgba(255,255,255,0)",
        fill: transparentize(0.25, theme.palette.accent),
      }
    : {
        scale: 1,
        stroke: "#fff",
        fill: transparentize(1, theme.palette.accent),
      },
  initial: {
    stroke: "rgba(255,255,255,0.5)",
    fill: transparentize(1, theme.palette.accent),
  },
  transition: {
    type: "spring",
    stiffness: 100,
  },
}))<{ isOverLink: boolean }>`
  transform-origin: center;
`;

export const GlobalCursorOverwrites = createGlobalStyle<{ isOverLink?: boolean }>`
  html {
    cursor: ${p => (p.isOverLink ? "none" : "url(/assets/innerCursor.svg) 3 3, auto")};
  }
`;
