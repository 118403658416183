import React from "react";
import { transparentize } from "polished";
import styled, { css } from "styled-components";

interface MenuIconProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  active: boolean;
}

const activeMixin = css`
  box-shadow: ${({ theme }) => theme.shadows.md};

  & > span:first-child {
    transform: rotate(45deg);
    top: 50%;
  }
  & > span:nth-child(2) {
    transform: rotate(-45deg);
    top: 50%;
  }

  border-color: ${({ theme }) => transparentize(0.5, theme.palette.onBackground.shade1)};
`;

const ButtonContainer = styled.button<MenuIconProps>`
  ${({ theme, active }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid ${transparentize(0.25, theme.palette.onBackground.shade1)};
    background: none;
    /* box-shadow: ${theme.shadows.sm}; */
    width: 2.25rem;
    height: 2.25rem;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    transition: border-color 0.2s ${theme.easings.easeInOut};

    &:hover {
      border-color: ${transparentize(0.5, theme.palette.onBackground.shade1)};
    }

    & > span {
      height: 0.125rem;
      position: absolute;
      background: ${theme.palette.onBackground.main};
      right: 25%;
      transition: transform 0.2s ease, top 0.2s ease;
      transform-origin: center;
    }

    & > span:first-child {
      width: 50%;
      top: 40%;
    }
    & > span:nth-child(2) {
      width: 50%;
      top: 60%;
    }

    ${active && activeMixin}
  `}
`;

export default function MenuIcon(props: MenuIconProps) {
  return (
    <ButtonContainer {...props}>
      <span />
      <span />
    </ButtonContainer>
  );
}
