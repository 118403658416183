import styled from "styled-components";
import { motion } from "framer-motion";
import { transparentize } from "polished";

const pageFadeTransitionDuration = 0.5;
const curtainTransitionDuration = 1.5;

// animation on first page render
const curtainInitialVariants = {
  initial: {
    y: "-100%",
  },
  enter: {
    y: ["-100%", "-200%"],
    transition: {
      duration: curtainTransitionDuration * 0.66,
      ease: [0.63, 0.04, 0.92, 0.37],
    },
  },
};
// transitions between pages
const curtainRegularVariants = {
  initial: {
    y: "0%",
  },
  enter: {
    y: ["0%", "-100%", "-200%"],
    transition: {
      duration: curtainTransitionDuration,
      ease: [0.63, 0.04, 0.92, 0.37],
    },
  },
};

export const Curtain = styled(motion.div).attrs({
  variants: {
    initial: ({ initialAnimation }) =>
      initialAnimation ? curtainInitialVariants.initial : curtainRegularVariants.initial,
    enter: ({ initialAnimation }) =>
      initialAnimation ? curtainInitialVariants.enter : curtainRegularVariants.enter,
  },
})`
  position: fixed;
  left: 0;
  bottom: -200%;
  height: calc(200% - 200px);
  width: 100%;
  pointer-events: none;
  background: #fff;
  box-shadow: 0 200px 0 0 ${transparentize(0.8, "#FFF")};
  z-index: 31;
`;

export const PageContent = styled(motion.main).attrs({
  variants: {
    initial: {
      opacity: 0,
    },
    enter: ({ initialAnimation }) => ({
      opacity: 1,
      transition: {
        duration: pageFadeTransitionDuration,
        delay: initialAnimation ? curtainTransitionDuration * 0.66 : curtainTransitionDuration,
        when: "beforeChildren",
      },
    }),
    exit: {
      opacity: 0,
      transition: { duration: pageFadeTransitionDuration },
    },
  },
})``;
