import React, { MouseEvent } from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";

interface IconButtonProps {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => any;
  href?: string;
  icon: string;
}

const rootElStyles = css`
  ${({ theme }) => css`
    cursor: pointer;
    background: none;
    border: none;
    height: 1.75rem;
    width: 1.75rem;
    display: inline-flex;
    padding: 0;
    opacity: 0.66;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease, color 0.2s ease;
    border-radius: 50%;
    outline: none;
    margin: 0;
    color: ${theme.palette.onBackground.main};

    &:hover {
      opacity: 1;
    }
  `}
`;

const ButtonWrapper = styled.button`
  ${rootElStyles}
`;

const LinkWrapper = styled.a`
  ${rootElStyles}
`;

export default function IconButton({ icon, href, ...props }: IconButtonProps) {
  const RootEl: any = href ? LinkWrapper : ButtonWrapper;
  return (
    <RootEl href={href} {...props}>
      <Icon icon={icon} />
    </RootEl>
  );
}
