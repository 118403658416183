import React from "react";
import { AnimatePresence } from "framer-motion";
import AnimatedText from "./AnimatedText";
import { IProject } from "~/types/Project";
import * as SC from "./style";
import useMedia from "~/utils/useMedia";

interface AnimatedSlideContentProps {
  visible: boolean;
  project: IProject;
}

function SlideContent({
  project: { name, technologies, subjects, type, prototype },
}: Pick<AnimatedSlideContentProps, "project">) {
  const showPrototype = useMedia(["(min-width: 800px)"], [true], false);

  return (
    <SC.SlideContentContainer key={name + "-container"} animate="animate" initial="initial">
      <SC.StyledGrid key={name + "-grid"}>
        <SC.AnimatedTextContainer>
          <AnimatedText key={name}>{name}</AnimatedText>
        </SC.AnimatedTextContainer>
        <SC.TagsContainer key={name + "-tags"}>
          {technologies.map(techName => (
            <SC.AnimatedChip key={name + "-tag-" + techName}>{techName}</SC.AnimatedChip>
          ))}
        </SC.TagsContainer>
      </SC.StyledGrid>
      {prototype && showPrototype && (
        <SC.PrototypeContainer key={name + "-protocontainer"}>
          <SC.PrototypeImg
            srcSet={(prototype.protoImgSrc.childImageSharp.fluid as any).srcSet}
            key={name + "-proto"}
          />
        </SC.PrototypeContainer>
      )}
      <SC.Footer key={name + "-footercontainer"}>
        <SC.FooterGridContent key={name + "-footer"}>
          <div>
            <label>Type</label>
            <p data-body2>{type}</p>
          </div>
          <div>
            <label>Subjects</label>
            <p data-body2>
              {subjects.reduce((acc, curr, i) => (i === 0 ? curr : acc + " ・ " + curr), "")}
            </p>
          </div>
        </SC.FooterGridContent>
      </SC.Footer>
    </SC.SlideContentContainer>
  );
}

export default function AnimatedSlideContent({ visible, project }: AnimatedSlideContentProps) {
  return (
    <AnimatePresence initial={false}>
      {visible && <SlideContent project={project} />}
    </AnimatePresence>
  );
}
