import { shade } from "polished";

const accentColor = "#006cff";
const background = "#0E1115";
const overlayBackground = shade(0.2, background);
const menuOverlay = "radial-gradient(#20374d, #0f1923f7)";
const onBackground = "#FFF";
const onBackgroundShade1 = "#AEB0B2";
const onBackgroundShade2 = "#2C2E30";
const backgroundGradient = "linear-gradient(90deg, #0E1115, #222933)";

export default {
  accent: accentColor,
  accent2: "#deb887",
  background,
  backgroundGradient,
  menuOverlay,
  overlayBackground,
  onBackground: {
    main: onBackground,
    shade1: onBackgroundShade1,
    shade2: onBackgroundShade2,
    card: {
      opaque: {
        shade1: "#555",
        main: "#ddd",
      },
      glassy: {
        shade1: onBackgroundShade1,
        main: onBackground,
      },
    },
  },
  card: {
    glassy: "#373c4859",
    opaque: "#22262f",
  },
};
