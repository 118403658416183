import React, { ReactNode } from "react";
import { FluidObject } from "gatsby-image";

import {
  SlideContainer,
  SlideContent,
  SlideBackgroundImgContainer,
  FixedFooterBackground,
  StyledBackgroundImage,
} from "./style";

interface SlideProps {
  bgImg?: FluidObject | FluidObject[];
  children: ReactNode;
  className?: string;
  href: string;
  visible?: boolean;
}

export default function Slide({ href, bgImg, visible, children, ...props }: SlideProps) {
  return (
    <SlideContainer to={href} $isShown={!!visible} {...props}>
      <SlideContent>{children}</SlideContent>
      <SlideBackgroundImgContainer animate={visible ? "shown" : "hidden"}>
        {/* @ts-ignore */}
        <StyledBackgroundImage fluid={bgImg} />
        <FixedFooterBackground />
      </SlideBackgroundImgContainer>
    </SlideContainer>
  );
}
