import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import fluidType from "~/utils/fluidType";
import PositionIndicator from "../PositionIndicator";
import BaseGrid from "../GridLayout";
import Image from "gatsby-image";
import { Link } from "gatsby";

export const SlidesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const SlideContainer = styled(Link)<{ $isShown: boolean }>`
  ${({ $isShown }) => css`
    color: inherit;
    display: contents;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: ${$isShown ? "all" : "none"};
  `}
`;

export const SlideContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const slideBackgroundMotionVariants = {
  shown: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const SlideBackgroundImgContainer = styled(motion.div).attrs({
  transition: {
    duration: 1,
    delay: 0.5,
    ease: "circOut",
  },
  variants: slideBackgroundMotionVariants,
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  z-index: 1;
  background-size: cover;
`;

export const StyledBackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

export const StyledPositionIndicator = styled(PositionIndicator)`
  ${({ theme }) => css`
    position: absolute;
    right: 0;
    bottom: -2rem;
    transform: translateY(-50%);
    z-index: 1;
    ${theme.dimensions.mixins.bodyPadding.horizontal}

    @media (max-width: 576px) {
      bottom: 10rem;
      top: unset;
      margin: 0;
      color: ${theme.palette.onBackground.shade2} !important;
    }
  `}
`;

export const PreviewContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 52.53%;
  cursor: pointer;
`;

export const PreviewContainerContent = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    span:first-child {
      z-index: 1;
      font-weight: 400;
      text-transform: uppercase;
      color: ${theme.palette.onBackground.main};
      ${fluidType(theme.breakpoints.minViewportLock, 16, theme.breakpoints.maxViewportLock, 23)}
    }
  `}
`;

export const PreviewFixedContainer = styled(BaseGrid)`
  ${({ theme }) => css`
    position: absolute;
    box-sizing: border-box;
    bottom: calc(${theme.dimensions.scrollableSectionPaddingBottom} + 3rem);
    width: 100%;
    pointer-events: none;
    ${theme.dimensions.mixins.centeredBody.static}

    & > * {
      grid-column: 9 / span 4;
      z-index: 1;
      pointer-events: all;
    }
  `}
`;

export const FixedFooterBackground = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8rem;
    background: ${theme.palette.background};
  `}
`;
