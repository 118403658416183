import styled, { css } from "styled-components";
import fluidType from "~/utils/fluidType";

export const PositionIndicatorContainer = styled.span`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: flex-end;
    line-height: 0.8;
    margin-bottom: 4rem;
    font-weight: 600;

    & > *:first-child {
      ${fluidType(theme.breakpoints.minViewportLock, 30, theme.breakpoints.maxViewportLock, 50)}
      margin-right: 0.2em;
    }
    & > *:last-child {
      ${fluidType(theme.breakpoints.minViewportLock, 20, theme.breakpoints.maxViewportLock, 12)}
    }
  `}
`;
