import React from "react";
import styled from "styled-components";
import { SplitText, WordWrapperProp, LineWrapperProp } from "@cyriacbr/react-split-text";
import { motion } from "framer-motion";

interface AnimatedTextProps {
  children: string;
}

const AnimatedTextContainer = styled.h1`
  line-height: 1.3em;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 2.6em;
  white-space: normal;
  overflow: hidden;
`;

const textAnimationVariants = ({ wordIndex }: any) => ({
  initial: {
    y: "100%",
    opacity: 1,
  },
  animate: {
    y: "0%",
    opacity: 1,
    transition: {
      ease: "circOut",
      duration: 1,
      delay: 0.125 * wordIndex + 0.25,
    },
  },
  exit: {
    opacity: 0,
    y: "50%",
    transition: {
      ease: "circOut",
      duration: 0.25,
      delay: 0.05 * wordIndex,
    },
  },
});

interface ExtraProps {
  wholeText: string;
}

const Word = styled(motion.span).attrs(textAnimationVariants)<{
  wordIndex: number;
}>`
  display: inline-block;
  white-space: pre;
`;

const WordWrapper: React.FC<WordWrapperProp<ExtraProps>> = ({ children, wordIndex }) => {
  return <Word wordIndex={wordIndex}>{children}</Word>;
};

const LineWrapper: React.FC<LineWrapperProp> = ({ children }) => (
  <span style={{ overflow: "hidden", display: "block" }}>{children}</span>
);

const MemoizedLineWrapper = React.memo(LineWrapper);
const MemoizedWordWrapper = React.memo(WordWrapper);

export default function AnimatedText({ children }: AnimatedTextProps) {
  return (
    <AnimatedTextContainer>
      <SplitText
        WordWrapper={MemoizedWordWrapper}
        LineWrapper={MemoizedLineWrapper}
        extraProps={{ wholeText: children }}>
        {children}
      </SplitText>
    </AnimatedTextContainer>
  );
}
