import { graphql, useStaticQuery } from "gatsby";

const useTechnologiesQuery = () => {
  return useStaticQuery(
    graphql`
      query {
        allTechnology(sort: { fields: index }) {
          nodes {
            name
            description
            logo
            index
            isFavorite
          }
        }
      }
    `,
  );
};

export default useTechnologiesQuery;
