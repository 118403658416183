import { useEffect, useRef } from "react";
import { useSpring } from "framer-motion";
import throttle from "lodash.throttle";

const springConfig = { stiffness: 120, damping: 20 };

export default function useMouseTracker() {
  const mousePosX = useSpring(-100, springConfig);
  const mousePosY = useSpring(-100, springConfig);
  const lastScrolledLeft = useRef(0);
  const lastScrolledTop = useRef(0);

  useEffect(() => {
    function handleMouseMove(event: MouseEvent) {
      let eventDoc, doc, body;

      event = event || window.event; // IE-ism

      // If pageX/Y aren't available and clientX/Y are,
      // calculate pageX/Y - logic taken from jQuery.
      // (This is to support old IE)
      if (event.pageX == null && event.clientX != null) {
        eventDoc = (event.target && (event.target as any).ownerDocument) || document;
        doc = eventDoc.documentElement;
        body = eventDoc.body;

        (event as any).pageX =
          event.clientX +
          ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
          ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
        (event as any).pageY =
          event.clientY +
          ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
          ((doc && doc.clientTop) || (body && body.clientTop) || 0);
      }
      mousePosX.set(event.pageX);
      mousePosY.set(event.pageY);
      (window as any).mouseX = event.pageX;
      (window as any).mouseY = event.pageY;
    }

    function handleScroll() {
      const nextScrollLeft = document.documentElement.scrollLeft;
      const nextScrollTop = document.documentElement.scrollTop;

      if (lastScrolledLeft.current !== nextScrollLeft) {
        const newPosX = (window as any).mouseX + (nextScrollLeft - lastScrolledLeft.current);
        (window as any).mouseX = newPosX;
        mousePosX.set(newPosX);
        lastScrolledLeft.current = nextScrollLeft;
      }
      if (lastScrolledTop.current !== nextScrollTop) {
        const newPosY = (window as any).mouseY + (nextScrollTop - lastScrolledTop.current);
        (window as any).mouseY = newPosY;
        mousePosY.set(newPosY);
        lastScrolledTop.current = nextScrollTop;
      }
    }

    const handleScrollThrottled = throttle(handleScroll, 50);

    if (!!document) {
      document.onmousemove = throttle(handleMouseMove, 50);
      window.addEventListener("scroll", handleScrollThrottled);
    }
    return () => {
      document.onmousemove = null;
      window.removeEventListener("scroll", handleScrollThrottled);
    };
  }, []);

  return { x: mousePosX, y: mousePosY };
}
