import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const Headline = styled.h1<{ inline?: boolean }>`
  ${({ inline }) => css`
    display: ${inline ? "inline-block" : "block"};
  `}
`;

export const MotionContainer = styled(motion.span).attrs({
  initial: "initial",
  variants: { initial: {}, animate: {} },
})``;

export const Word = styled.span<{
  isHighlighted?: boolean;
}>`
  ${({ theme, isHighlighted }) => css`
    display: inline-block;
    white-space: pre;
    color: ${isHighlighted ? theme.palette.accent : "inherit"};
  `}
`;

const riseTransition = (countIndex: number) => ({
  ease: [0.77, 0, 0.175, 1],
  duration: 1,
  delay: 0.018 * countIndex,
});

export const Letter = styled(motion.span).attrs(({ countIndex }: any) => ({
  variants: {
    initial: {
      opacity: 0,
      y: "100%",
    },
    animate: {
      opacity: 1,
      y: "0%",
    },
  },
  transition: riseTransition(countIndex),
}))<{ countIndex: number }>`
  display: inline-block;
`;
