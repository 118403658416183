import React from "react";
import { FullpageApi } from "@fullpage/react-fullpage";

interface ScrollContextProviderValue {
  fullpageApi: React.MutableRefObject<FullpageApi | null>;
  isFullpageLoaded: boolean;
  setIsFullpageLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  scrollIndex: number | null;
  setScrollIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

const ScrollContext = React.createContext({} as ScrollContextProviderValue);

export const ScrollContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [scrollIndex, setScrollIndex] = React.useState<number | null>(null);
  const [isFullpageLoaded, setIsFullpageLoaded] = React.useState<boolean>(false);
  const fullpageApiRef = React.useRef<FullpageApi | null>(null);

  return (
    <ScrollContext.Provider
      value={{
        fullpageApi: fullpageApiRef,
        isFullpageLoaded,
        setIsFullpageLoaded,
        scrollIndex,
        setScrollIndex,
      }}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollContext;
export const useScrollContext = () => React.useContext(ScrollContext);
