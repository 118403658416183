import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import fluidType from "~/utils/fluidType";
/* @ts-ignore */
import Tilt from "react-tilt";

export const OverlayContainer = styled(motion.div).attrs({
  variants: {
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.07,
        when: "beforeChildren",
      },
    },
    initial: {
      opacity: 0,
    },
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0.04,
        staggerDirection: -1,
        when: "afterChildren",
      },
    },
  },
  animate: "animate",
  exit: "exit",
  initial: "initial",
})`
  ${({ theme }) => css`
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${theme.palette.menuOverlay};
    box-sizing: border-box;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(6px);
    }
  `}
`;

export const MainContainer = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${theme.dimensions.mixins.centeredBody.static}
  `}
`;

export const MenuLink = styled(motion.span).attrs({
  variants: {
    initial: {
      y: 10,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: 10,
      opacity: 0,
      transition: {
        duration: 0.15,
      },
    },
  },
})`
  ${({ theme }) => css`
    a {
      ${fluidType(theme.breakpoints.minViewportLock, 26, theme.breakpoints.maxViewportLock, 39)}
      color: ${theme.palette.onBackground.shade1};
    }
    padding: 0.5em 0;
  `}
`;

export const TiltContainer = styled(Tilt).attrs({
  className: "Tilt",
  options: { max: 25, axis: "Y", scale: 1 },
})`
  position: absolute;
  height: 100vh;
  width: 100vw;
`;

export const LogoContainer = styled.div.attrs({ className: "Tilt-inner" })`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.05;
  height: 12vw;
  max-height: 120px;

  & > svg {
    height: 100%;
  }
`;
