import styled, { css } from "styled-components";
import { transparentize } from "polished";
import theme from "~/theme";
import { TextLink } from "~/components/Link";
import fluidType from "~/utils/fluidType";
import Image from "gatsby-image";

export const BackgroundContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;

    h1,
    h5 {
      color: ${theme.palette.onBackground.shade1};
    }

    h1 em,
    h5 em {
      color: ${theme.palette.onBackground.main};
    }
  `}
`;

export const BackgroundImage = styled(Image)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: -1;

  img {
    object-position: 50% 5% !important;
  }
`;

export const TextContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    box-sizing: border-box;
    ${theme.dimensions.mixins.centeredBody.static}

    & > * {
      max-width: 40rem;
    }
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.palette.overlayBackground};
  `}
`;

export const FooterContent = styled.div`
  ${({ theme }) => css`
    height: 7rem;
    display: flex;
    color: ${theme.palette.onBackground.shade2};
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
    ${theme.dimensions.mixins.centeredBody.static}
  `}
`;

export const StyledLink = styled(TextLink).attrs({ hoverColor: theme.palette.accent })`
  ${({ theme }) => css`
    color: ${transparentize(0.33, theme.palette.accent)};
    ${fluidType(theme.breakpoints.minViewportLock, 26, theme.breakpoints.maxViewportLock, 39)}
    font-weight: 600;
    margin: 1rem 0;
  `}
`;

export const ContactText = styled.p`
  ${({ theme }) => css`
    ${fluidType(theme.breakpoints.minViewportLock, 20, theme.breakpoints.maxViewportLock, 31)}
    color: ${theme.palette.onBackground.shade1};
    font-weight: 600;
  `}
`;
