import { graphql, useStaticQuery } from "gatsby";

// would prefer to use the query as a variable that is imported from ./projects
// unfortunately this is not (yet?) possible
// https://github.com/gatsbyjs/gatsby/issues/26703
const useProjectsQuery = () => {
  return useStaticQuery(
    graphql`
      query {
        allProject(sort: { fields: index }) {
          nodes {
            bgImgSrc {
              childImageSharp {
                fluid(maxWidth: 1920, srcSetBreakpoints: [1600, 2800, 3020], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            heroImgSrc {
              childImageSharp {
                fluid(maxWidth: 1920, srcSetBreakpoints: [1600, 2800, 3020], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            name
            subjects
            technologies
            type
            date
            timeToRead
            index
            body
            slug
            prototype {
              protoImgSrc {
                childImageSharp {
                  fluid(maxWidth: 1000, srcSetBreakpoints: [800, 1400, 2000], quality: 85) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
};

export default useProjectsQuery;
