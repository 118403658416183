// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-twportfolio-src-pages-404-tsx": () => import("./../../../../gatsby-theme-twportfolio/src/pages/404.tsx" /* webpackChunkName: "component---gatsby-theme-twportfolio-src-pages-404-tsx" */),
  "component---gatsby-theme-twportfolio-src-pages-imprint-tsx": () => import("./../../../../gatsby-theme-twportfolio/src/pages/imprint.tsx" /* webpackChunkName: "component---gatsby-theme-twportfolio-src-pages-imprint-tsx" */),
  "component---gatsby-theme-twportfolio-src-pages-index-tsx": () => import("./../../../../gatsby-theme-twportfolio/src/pages/index.tsx" /* webpackChunkName: "component---gatsby-theme-twportfolio-src-pages-index-tsx" */),
  "component---gatsby-theme-twportfolio-src-templates-project-template-tsx": () => import("./../../../../gatsby-theme-twportfolio/src/templates/project.template.tsx" /* webpackChunkName: "component---gatsby-theme-twportfolio-src-templates-project-template-tsx" */),
  "component---gatsby-theme-twportfolio-src-templates-projects-template-tsx": () => import("./../../../../gatsby-theme-twportfolio/src/templates/projects.template.tsx" /* webpackChunkName: "component---gatsby-theme-twportfolio-src-templates-projects-template-tsx" */)
}

