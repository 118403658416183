import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import Image from "gatsby-image";

export const TypedWrapper = styled.h1`
  margin: 0;
  transform: translate(0);
`;

export const BackgroundContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;

    h1,
    h5 {
      color: ${theme.palette.onBackground.shade1};
    }

    h1 em {
      color: ${theme.palette.accent};
    }

    h5 em {
      color: ${theme.palette.onBackground.main};
    }
  `}
`;

export const BackgroundImage = styled(Image)`
  position: absolute !important;
  width: 100%;
  height: 100%;
`;

export const TextContent = styled(motion.div).attrs({
  initial: "initial",
  animate: "animate",
  exit: "exit",
  variants: {
    animate: {
      transition: {
        staggerChildren: 0.5,
        delay: 1,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
    },
  },
})`
  ${({ theme }) => css`
    width: 100%;
    box-sizing: border-box;
    ${theme.dimensions.mixins.centeredBody.static}

    h5,
    h1 {
      max-width: 40rem;
    }
  `}
`;
