/**
 * From fullpage.js
 * https://github.com/alvarotrigo/fullPage.js/blob/ed9979ec5a5edcf8a8f303018e386abb19e3164a/dist/fullpage.js#L1501
 * */

export default function getAverage(elements: Array<number>, number: number) {
  let sum = 0;

  //taking `number` elements from the end to make the average, if there are not enought, 1
  const lastElements = elements.slice(Math.max(elements.length - number, 1));

  for (let i = 0; i < lastElements.length; i++) {
    sum = sum + lastElements[i];
  }

  return Math.ceil(sum / number);
}
