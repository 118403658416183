import React, { useRef, useCallback } from "react";
import { useLocation } from "@reach/router";
import { TextLink } from "~/components/Link";
import TwLogo from "~/components/TwLogo";
import { OverlayContainer, MenuLink, MainContainer, LogoContainer, TiltContainer } from "./style";

interface RouteLink {
  href: string;
  name: string;
}

interface MenuOverlayProps {
  routeLinks: RouteLink[];
  closePortal: (e: any) => any;
}

export default function MenuOverlay({ routeLinks, closePortal }: MenuOverlayProps) {
  const { pathname } = useLocation();
  const isHome = pathname === "/";
  // React Tilt misses types, we have to use any here
  const tiltContainerRef = useRef<any>();

  /**
   * re-dispatches the hover event to the underlying tilt container in order
   * to maintain the tilt position of the background logo
   */
  const handleMouseMoveOnLink: React.MouseEventHandler = useCallback(e => {
    if (e && e.nativeEvent) {
      const { clientX, clientY } = e.nativeEvent;
      const reproducedEvent = new MouseEvent("mousemove", { bubbles: true, clientX, clientY });

      tiltContainerRef.current?.element?.dispatchEvent(reproducedEvent);
    }
  }, []);
  return (
    <OverlayContainer key="container">
      <TiltContainer ref={tiltContainerRef}>
        <LogoContainer>
          <TwLogo animateOnHover />
        </LogoContainer>
      </TiltContainer>
      <MainContainer>
        {routeLinks.map(({ href, name }) => {
          const linkJsx = isHome ? (
            <TextLink href={`/${href}`} onMouseMove={handleMouseMoveOnLink}>
              {name}
            </TextLink>
          ) : (
            <TextLink to={`/${href}`} onMouseMove={handleMouseMoveOnLink}>
              {name}
            </TextLink>
          );
          return (
            <MenuLink onClick={closePortal} key={href}>
              {linkJsx}
            </MenuLink>
          );
        })}
      </MainContainer>
    </OverlayContainer>
  );
}
