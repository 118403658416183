import { MotionValue } from "framer-motion";
import React from "react";
import useMouseTracker from "./useMouseTracker";

interface CursorContextProviderValue {
  mousePos: {
    x: MotionValue;
    y: MotionValue;
  };
  isOverLink: boolean;
  setIsOverLink: React.Dispatch<React.SetStateAction<boolean>>;
}

const CursorContext = React.createContext({} as CursorContextProviderValue);

export const CursorContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOverLink, setIsOverLink] = React.useState(false);
  const animatedMouseStyle = useMouseTracker();

  return (
    <CursorContext.Provider
      value={{
        isOverLink,
        setIsOverLink,
        mousePos: animatedMouseStyle,
      }}>
      {children}
    </CursorContext.Provider>
  );
};

export default CursorContext;
export const useCursorContext = () => React.useContext(CursorContext);
