import fluidType from "../utils/fluidType";
import { breakpoints } from "./media";

export const fluidTypeApp = (minFontSize: number, maxFontSize: number, prop = "font-size") =>
fluidType(
  breakpoints.minViewportLock,
  minFontSize,
  breakpoints.maxViewportLock,
  maxFontSize,
  prop,
);

