import React, { ReactNode, useCallback, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { WindowLocation } from "@reach/router";
import { PageContent, Curtain } from "./style";

interface PageTransitionWrapperProps {
  children: ReactNode;
  location: WindowLocation;
}

export default function PageTransitionWrapper({ children, location }: PageTransitionWrapperProps) {
  const [isFirstAnimationComplete, setIsFirstAnimationComplete] = useState(false);
  const handleComplete = useCallback(() => {
    setIsFirstAnimationComplete(true);
    console.log("first animation done");
  }, [setIsFirstAnimationComplete]);

  return (
    <AnimatePresence>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        key={location.pathname + "-wrapper"}
        onAnimationComplete={!isFirstAnimationComplete ? handleComplete : undefined}>
        <PageContent
          custom={{ initialAnimation: !isFirstAnimationComplete }}
          key={location.pathname}>
          {children}
        </PageContent>
        <Curtain
          custom={{ initialAnimation: !isFirstAnimationComplete }}
          key={location.pathname + "-curtain"}
        />
      </motion.div>
    </AnimatePresence>
  );
}
