import { createGlobalStyle, css } from "styled-components";
import palette from "./colors";
import { fluidTypeApp } from "./utils";
import normalize from "styled-normalize";


const base = css`
html {
  font-family: "Inter", -apple-system, "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: url(/assets/innerCursor.svg) 3 3, auto;
}
body {
  background: ${palette.backgroundGradient};
  /* background-color: ${palette.background}; */
  color: ${palette.onBackground.main};
  max-width: 100%;
  overflow-x: hidden;
}

em {
  font-style: normal;
}

h1 {
  ${fluidTypeApp(32, 49)}
  font-weight: 600;
}

h2 {
  ${fluidTypeApp(26, 39)}
  font-weight: 600;
}

h3 {
  ${fluidTypeApp(20, 31)}
  font-weight: 600;
}

h4 {
  ${fluidTypeApp(17, 25)}
  font-weight: 600;
}

h5 {
  ${fluidTypeApp(16, 20)}
  font-weight: 600;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
}

p,
a {
  font-size: 1.125rem;
  line-height: 1.756;
  max-width: 680px;
  b {
    font-weight: 800;
  }
  ${({ theme }) => theme.media.desktop`
    max-width: 507px;
  `}
  ${({ theme }) => theme.media.tablet`
    max-width: 486px;
  `};
}

label {
  font-size: 0.875rem;
  font-weight: 600;
  color: ${palette.onBackground.shade2};
  text-transform: uppercase;
}

a {
  color: ${palette.accent2};
  text-decoration: none;
  font-weight: 600;
  opacity: 0.8;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }
}

code {
  font-family: Fira Code;
  padding: 0.1em 0.2em;
  color: ${palette.accent2};
  border-radius: 0.2em;
  background: rgba(255,255,255,0.1);
}

p[data-body2] {
  font-size: 0.875rem;
}
`;

export const GlobalStyles = createGlobalStyle`
${normalize}
${base}
`;