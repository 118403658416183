import { css } from "styled-components";
/* 
Used for fluid typography between two points.
Uses a linear function in between these points and
a constant outside these two points
*/

// prettier-ignore
/* eslint-disable */

function fluidType(
  minViewport: number,
  minSize: number,
  maxViewport: number,
  maxSize: number,
  prop = "font-size",
) {
  const slope = (maxSize - minSize) / (maxViewport - minViewport);
  const b = minSize - slope * minViewport;
  const fluidRule = `calc(${slope * 100}vw + ${b}px)`;

  return css`
    ${prop}: ${minSize}px;
    @media (min-width: ${minViewport}px) {
      ${prop}: ${fluidRule};
    }
    @media (min-width: ${maxViewport}px) {
      ${prop}: ${maxSize}px;
    }
  `;
}

export default fluidType;
