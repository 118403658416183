import { css } from "styled-components";

const roundedMobileEdgesMixin = css`
  ${({ theme }) => theme.media.phablet`
  &::before {
    content: "";
    width: 100%;
    height: 13px;
    background: ${theme.palette.onBackground.main};
    position: absolute;
    left: 0;
    top: 0;
    z-index: -2;
  }
  &::after {
    content: "";
    width: 100%;
    height: 14px;
    background: linear-gradient(
      90deg, #0e1015, #202730);
    position: absolute;
    left: 0;
    top: 0px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: -1;
  }
`}
`;

export const globalMixins = { roundedMobileEdgesMixin };
