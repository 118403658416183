import React, { ReactNode, useCallback, useState } from "react";
import { useAnimation, useViewportScroll } from "framer-motion";
import {
  SplitText,
  WordWrapperProp,
  LetterWrapperProp,
  LineWrapperProp,
} from "@cyriacbr/react-split-text";
import { InView } from "react-intersection-observer";
import { Headline as H, Word, Letter, MotionContainer } from "./style";

const TRIGGER_INVIEW_BUFFER = 100;

type HeadlineHtmlTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface OverflownHeadlineProps extends React.HTMLProps<HTMLHeadingElement> {
  as: HeadlineHtmlTag;
  highlighted?: string[];
  inline?: boolean;
  children: ReactNode;
  showInView?: boolean;
}
type ExtraProps = Pick<OverflownHeadlineProps, "highlighted" | "inline"> & { wholeText: string };

const getWrapper = (as: HeadlineHtmlTag) => {
  let w: React.FC;
  switch (as) {
    case "h1": {
      w = ({ children, ...props }) => (
        <H as="h1" {...props}>
          {children}
        </H>
      );
      break;
    }
    case "h2": {
      w = ({ children, ...props }) => (
        <H as="h2" {...props}>
          {children}
        </H>
      );
      break;
    }
    case "h3": {
      w = ({ children, ...props }) => (
        <H as="h3" {...props}>
          {children}
        </H>
      );
      break;
    }
    case "h4": {
      w = ({ children, ...props }) => (
        <H as="h4" {...props}>
          {children}
        </H>
      );
      break;
    }
    case "h5": {
      w = ({ children, ...props }) => (
        <H as="h5" {...props}>
          {children}
        </H>
      );
      break;
    }
    default:
      w = ({ children, ...props }) => (
        <H as="h6" {...props}>
          {children}
        </H>
      );
  }
  return w;
};

const LineWrapper: React.FC<LineWrapperProp> = ({ children }) => (
  <span style={{ overflow: "hidden", display: "block" }}>{children}</span>
);

const LetterWrapper: React.FC<LetterWrapperProp> = ({ children, countIndex }) => {
  return <Letter countIndex={countIndex}>{children}</Letter>;
};

const WordWrapper: React.FC<WordWrapperProp<ExtraProps>> = ({
  children,
  wordIndex,
  extraProps,
}) => {
  const currentWord = extraProps?.wholeText.split(" ")[wordIndex];
  const isHighlighted = Boolean(
    currentWord && extraProps?.highlighted?.find(e => e === currentWord),
  );

  return <Word isHighlighted={isHighlighted}>{children}</Word>;
};

const MemoizedWordWrapper = React.memo(WordWrapper);
const MemoizedLetterWrapper = React.memo(LetterWrapper);
const MemoizedLineWrapper = React.memo(LineWrapper);

function OverflownHeadline({
  children,
  as,
  highlighted,
  showInView,
  ...props
}: OverflownHeadlineProps) {
  const [isInView, setIsInView] = useState(false);
  const Wrapper = getWrapper(as);
  const controls = useAnimation();
  const { scrollY } = useViewportScroll();

  const innerJsx = (
    <SplitText
      WordWrapper={MemoizedWordWrapper}
      LineWrapper={MemoizedLineWrapper}
      LetterWrapper={MemoizedLetterWrapper}
      extraProps={{ wholeText: children, highlighted }}>
      {children}
    </SplitText>
  );

  const handleInViewChange = useCallback(
    (newInView, entry) => {
      if (newInView && entry && !isInView) {
        controls.start("animate");
        setIsInView(true);
      } else {
        const topPos = entry?.boundingClientRect?.top;
        const pageYOffset = entry?.target?.ownerDocument?.defaultView?.pageYOffset;
        if (topPos && pageYOffset) {
          // only hide when the content is below the viewport.
          // If the user has scrolled past it, let it stay visible
          const contentTopInViewport = topPos + pageYOffset - TRIGGER_INVIEW_BUFFER;
          const contentIsAboveView = contentTopInViewport < scrollY.get();
          if (!contentIsAboveView) {
            controls.start("initial");
            setIsInView(false);
          }
        }
      }
    },
    [controls, scrollY],
  );

  return (
    <Wrapper {...props}>
      <MotionContainer animate={!!showInView && controls ? controls : "animate"}>
        {showInView ? (
          <InView
            as="span"
            onChange={handleInViewChange}
            rootMargin={!isInView ? `-${TRIGGER_INVIEW_BUFFER}px 0px` : "0px"}>
            {innerJsx}
          </InView>
        ) : (
          innerJsx
        )}
      </MotionContainer>
    </Wrapper>
  );
}

type MemoComparableProps = Readonly<React.PropsWithChildren<OverflownHeadlineProps>>;

export default React.memo(
  OverflownHeadline,
  (prevProps: MemoComparableProps, nextProps: MemoComparableProps) => {
    const { highlighted: p_highlighted, ...p_props } = prevProps;
    const { highlighted: n_highlighted, ...n_props } = nextProps;
    if (p_highlighted && n_highlighted) {
      if (!p_highlighted.every((x, i) => x === n_highlighted[i])) {
        return false;
      }
    }
    for (const [p_propKey, p_propValue] of Object.entries(p_props)) {
      if ((n_props as { [key: string]: any })[p_propKey] !== p_propValue) {
        return false;
      }
    }

    return true;
  },
);
