import React from "react";
import {
  BackgroundContainer,
  TextContent,
  StyledLink,
  ContactText,
  BackgroundImage,
} from "./style";
import { graphql, useStaticQuery } from "gatsby";
import Footer from "~/components/Footer";

export default function About() {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "aboutBg.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1920, srcSetBreakpoints: [1600, 2800, 3020], quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <BackgroundContainer>
        {/* @ts-ignore */}
        <BackgroundImage fluid={image.childImageSharp.fluid} objectPosition="50% 5%" />
        <TextContent>
          <h5>About me</h5>
          <h1>
            Hey, I am <em>Tim Weise</em>.<br /> I am a <em>Web Developer</em> based in{" "}
            <em>Stuttgart, Germany</em>.
          </h1>
          <ContactText>
            Reach me at <StyledLink href="mailto:hello@timweise.com">hello@timweise.com</StyledLink>
          </ContactText>
        </TextContent>
      </BackgroundContainer>
      <Footer />
    </>
  );
}
