import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Card, StyledIcon } from "./style";

interface TiltCardProps {
  tiltOptions: object;
  icon: string;
  className?: string;
}

export default function TiltCard({ icon, tiltOptions, ...props }: TiltCardProps) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [currentIcon, setCurrentIcon] = useState<string>(icon);
  const [isIconHidden, setIsIconHidden] = useState(false);
  const controls = useAnimation();
  useEffect(() => {
    async function runFlipAnimation() {
      setTimeout(() => {
        setIsIconHidden(true);
        setCurrentIcon(icon);
      }, 100);
      setTimeout(() => {
        setIsIconHidden(false);
      }, 300);
      setIsFlipped(flipped => !flipped);
      await controls.start({
        rotateY: isFlipped ? -360 : 0,
        scale: [1, 1.04, 1, 1],
        transition: {
          type: "spring",
          damping: 20,
          mass: 2,
        },
      });
    }
    runFlipAnimation();
  }, [icon]);
  return (
    <motion.div animate={controls} {...props}>
      <Card options={tiltOptions}>
        <StyledIcon hidden={isIconHidden} icon={currentIcon} />
      </Card>
    </motion.div>
  );
}

TiltCard.defaultProps = {
  tiltOptions: {
    scale: 1.01,
    max: 15,
  },
};
