import React from "react";
import { AnimatedCursor, Circle, GlobalCursorOverwrites } from "./style";
import { useCursorContext } from "./CursorContext";
import Arrow from "./Arrow";

export default function FollowingCursor() {
  const { mousePos: animatedMouseStyle, isOverLink } = useCursorContext();
  return (
    <>
      <GlobalCursorOverwrites isOverLink={isOverLink} />
      <AnimatedCursor viewBox="0 0 100 100" style={animatedMouseStyle}>
        <Circle cx="50" cy="50" r="47.5" strokeWidth={3} isOverLink={isOverLink} />
        <Arrow isOverLink={isOverLink} />
      </AnimatedCursor>
    </>
  );
}
