import React, { Children, ReactElement, cloneElement } from "react";
import { SlidesContainer, StyledPositionIndicator } from "./style";

interface SlideshowProps {
  children: ReactElement | ReactElement[];
  inView?: boolean;
  activeSlideIndex: number;
  onNextSlideClick: () => any;
}

export default function Slideshow({ children, activeSlideIndex }: SlideshowProps) {
  const numberOfSlides = Children.count(children);

  return (
    <>
      <SlidesContainer>
        {Children.map(children, (child, index) =>
          cloneElement(child, { visible: activeSlideIndex === index }),
        )}
      </SlidesContainer>
      <StyledPositionIndicator position={activeSlideIndex} length={numberOfSlides} />
    </>
  );
}
