import styled, { css } from "styled-components";
/* @ts-ignore */
import Tilt from "react-tilt";
import Icon from "~/components/Icon";

export const Card = styled(Tilt)`
  ${({ theme }) => css`
    background: ${theme.palette.card.opaque};
    border-radius: 0.75rem;
    box-shadow: ${theme.shadows.sm};
    transition: box-shadow 0.3s cubic-bezier(0.03, 0.98, 0.52, 0.99);
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:hover {
      box-shadow: ${theme.shadows.md};
    }
  `}
`;

export const StyledIcon = styled(Icon)<{ hidden?: boolean }>`
  ${({ hidden, theme }) => css`
    position: absolute;
    top: 50%;
    left: 15%;
    width: 100%;
    transform-origin: top left;
    transform: scale(1.3);
    color: ${theme.palette.onBackground.card.opaque.main};
    padding-bottom: 100%;
    opacity: ${hidden ? 0 : 1};
  `}
`;
