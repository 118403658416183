/* wheel event handlers modified from the fullpage.js implementation */
/* eslint-disable @typescript-eslint/no-this-alias */
import getAverage from "~/utils/getAverage";

export interface CustomScrollEvent {
  direction: "down" | "up";
}

export default class CustomScroller {
  private prevTime = new Date().getTime();
  private scrollings: Array<number> = [];

  private callback: (e: CustomScrollEvent) => any;
  // We have to store a reference to the actual event listener that will
  // call the callback internally, so that we can safely remove it inside
  // `removeScrollTracker` by passing the correct reference
  private eventListenerCb?: EventListener | EventListenerObject | HammerListener;
  private gestureManager: HammerManager | null = null;

  private isMobile: boolean;
  private canScroll: boolean;

  private initTime: number;

  constructor(callback: (e: CustomScrollEvent) => any) {
    this.callback = callback;
    this.isMobile = this.checkTouchscreen();
    this.canScroll = true;
    this.initTime = new Date().getTime();
    this.addScrollTracker();
  }

  private checkTouchscreen() {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }

  private MouseWheelHandler(e: any) {
    const curTime = new Date().getTime();

    e = e || window.event;
    const value = e.wheelDelta || -e.deltaY || -e.detail;
    const delta = Math.max(-1, Math.min(1, value));
    const horizontalDetection =
      typeof e.wheelDeltaX !== "undefined" || typeof e.deltaX !== "undefined";
    const isScrollingVertically =
      Math.abs(e.wheelDeltaX) < Math.abs(e.wheelDelta) ||
      Math.abs(e.deltaX) < Math.abs(e.deltaY) ||
      !horizontalDetection;

    // Limiting the array to 150 (lets not waste memory!)
    if (this.scrollings.length > 149) {
      this.scrollings.shift();
    }
    // keeping record of the previous scrollings
    this.scrollings.push(Math.abs(value));
    const timeDiff = curTime - this.prevTime;
    this.prevTime = curTime;
    // haven't they scrolled in a while?
    // (enough to be consider a different scrolling action to scroll another section)
    if (timeDiff > 200) {
      // emptying the array, we dont care about old scrollings for our averages
      this.scrollings = [];
    }

    // Don't pick up initial scroll events from scrolling in with regular Fullpage.js
    if (curTime - this.initTime < 200) {
      this.initTime = curTime;
    } else if (this.canScroll) {
      // Get average scroll value of the last 10 values
      const averageEnd = getAverage(this.scrollings, 10);
      // Get average scroll value of the last 70 values
      const averageMiddle = getAverage(this.scrollings, 70);
      // Is the scrolling accelerating? (is the average value at the end bigger than in the middle?)
      const isAccelerating = averageEnd >= averageMiddle;

      if (isAccelerating && isScrollingVertically) {
        //scrolling down?
        if (delta < 0) {
          this.callback({ direction: "down" });
          //scrolling up?
        } else {
          this.callback({ direction: "up" });
        }
        this.canScroll = false;
        // Block scrolling for the transition duration
        setTimeout(() => {
          this.canScroll = true;
        }, 1000);
      }
    }
  }

  private addMouseWheelHandler() {
    let prefix = "";
    let _addEventListener;

    if (window.addEventListener !== undefined) {
      _addEventListener = "addEventListener";
    } else {
      _addEventListener = "attachEvent";
      prefix = "on";
    }

    // detect available wheel event
    // let's assume that browsers that support neither "wheel" nor "mousewheel" are older Firefox
    let support = "DOMMouseScroll";
    if ("onwheel" in document.createElement("div")) {
      support = "wheel"; // Modern browsers support "wheel"
    } else if ((document as any).onmousewheel !== undefined) {
      support = "mousewheel"; // Webkit and IE support at least "mousewheel"
    }
    let gSupportsPassive = false;
    try {
      const opts = Object.defineProperty({}, "passive", {
        get: () => {
          gSupportsPassive = true;
        },
      });
      /* @ts-ignore */
      window.addEventListener("testPassive", null, opts);
      /* @ts-ignore */
      window.removeEventListener("testPassive", null, opts);
    } catch (e) {}
    const passiveEvent = gSupportsPassive ? { passive: false } : false;

    if (support == "DOMMouseScroll") {
      const that = this;
      (document as any)[_addEventListener](
        prefix + "MozMousePixelScroll",
        (that.eventListenerCb = this.MouseWheelHandler.bind(this)),
        passiveEvent,
      );
    }

    //handle MozMousePixelScroll in older Firefox
    else {
      const that = this;
      (document as any)[_addEventListener](
        prefix + support,
        /* use arrow fn for this context */
        (that.eventListenerCb = this.MouseWheelHandler.bind(this)),
        passiveEvent,
      );
    }
  }

  /**
   * https://github.com/alvarotrigo/fullPage.js/blob/ed9979ec5a5edcf8a8f303018e386abb19e3164a/dist/fullpage.js#L3082
   * Removes the auto scrolling action fired by the mouse wheel and trackpad.
   * After this function is called, the mousewheel and trackpad movements won't scroll through sections.
   */
  private removeMouseWheelHandler() {
    if (this.eventListenerCb) {
      if (!!document.addEventListener) {
        document.removeEventListener(
          "mousewheel",
          this.eventListenerCb as EventListenerOrEventListenerObject,
          false,
        ); //IE9, Chrome, Safari, Oper
        document.removeEventListener(
          "wheel",
          this.eventListenerCb as EventListenerOrEventListenerObject,
          false,
        ); //Firefox
        document.removeEventListener(
          "MozMousePixelScroll",
          this.eventListenerCb as EventListenerOrEventListenerObject,
          false,
        ); //old Firefox
      } else {
        /* @ts-ignore */
        document.detachEvent("onmousewheel", this.eventListenerCb); //IE 6/7/8
      }
    }
  }

  /**
   * triggers a callback on swipes and mouse wheel events
   * "scroll" events are only triggered on overflown elements i.e.
   * wheel events have to be used on desktop and swipe events on touch screens
   */
  public addScrollTracker() {
    if (this.isMobile) {
      import("hammerjs").then(
        ({ DIRECTION_UP, DIRECTION_VERTICAL, Manager: GestureManager, Swipe: SwipeGesture }) => {
          const gestureManager = new GestureManager(window);
          gestureManager.add(new SwipeGesture({ direction: DIRECTION_VERTICAL }));
          const swipeListener = (e: HammerInput) => {
            // swipe up means scroll down (!)
            this.callback({ direction: e.direction === DIRECTION_UP ? "down" : "up" });
          };
          gestureManager.on("swipe", swipeListener);
          this.gestureManager = gestureManager;
          this.eventListenerCb = swipeListener;
        },
      );
    } else {
      this.addMouseWheelHandler();
    }
  }

  public removeScrollTracker() {
    if (this.isMobile && this.gestureManager) {
      this.gestureManager.off("swipe", this.eventListenerCb as HammerListener);
      this.gestureManager.destroy();
    } else {
      this.removeMouseWheelHandler();
    }
  }
}
