import React from "react";
import { FooterContent, Container } from "./style";
import { TextLink } from "~/components/Link";

export default function Footer() {
  return (
    <Container>
      <FooterContent>
        <p data-body2>© {new Date().getFullYear()} Tim Weise</p>
        <div>
          <TextLink to="/imprint">Imprint</TextLink>
        </div>
      </FooterContent>
    </Container>
  );
}
