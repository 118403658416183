import { useRef, useEffect, useCallback, Dispatch, SetStateAction, useState } from "react";
import { useScrollContext } from "~/contexts/ScrollContext";
import CustomScroller, { CustomScrollEvent } from "~/utils/customScrollHandler";

export default function useSlideshowScrolling(
  slideIndex: number,
  currentSlide: number,
  numberOfSlides: number,
  setCurrentSlide: Dispatch<SetStateAction<number>>,
) {
  const { fullpageApi, isFullpageLoaded, scrollIndex } = useScrollContext();
  const customScrollerRef = useRef<CustomScroller | null>(null);
  const savedScrollHandler = useRef<(e: CustomScrollEvent) => any>();
  /* the scrollHandler Cb must indicate if a scroll on one of the "edge slides"
   (first or last) has occured.
   In this case an effect can move in the appropriate fp direction and 
   reenable the autoScrolling of Fullpage.js */
  const [isReenablingFp, setIsReenablingFp] = useState<"down" | "up" | null>(null);

  const inView = scrollIndex === slideIndex;

  const scrollHandler = useCallback(
    (e: CustomScrollEvent) => {
      if (inView) {
        if (e.direction === "down") {
          setCurrentSlide(slideIndex => {
            if (slideIndex + 1 < numberOfSlides) {
              return slideIndex + 1;
            }
            setIsReenablingFp("down");
            return slideIndex;
          });
        } else {
          setCurrentSlide(slideIndex => {
            if (slideIndex - 1 >= 0) {
              return slideIndex - 1;
            }
            setIsReenablingFp("up");
            return slideIndex;
          });
        }
      }
    },
    [numberOfSlides, inView, setCurrentSlide, setIsReenablingFp],
  );

  useEffect(() => {
    savedScrollHandler.current = scrollHandler;
  }, [scrollHandler]);

  useEffect(() => {
    if (isFullpageLoaded && fullpageApi.current) {
      if (inView && !isReenablingFp) {
        // Block all Fullpage.js scrolling in between slides
        console.log("Blocking fp");
        fullpageApi.current.setAllowScrolling(false);
      } else if (inView && isReenablingFp) {
        console.log("Reenabling fp");
        if (isReenablingFp === "down") {
          fullpageApi.current.moveSectionDown();
        } else {
          fullpageApi.current.moveSectionUp();
        }
        fullpageApi.current.setAllowScrolling(true);
        setIsReenablingFp(null);
      } else {
        console.log("Reenabling fp");
        fullpageApi.current.setAllowScrolling(true);
      }
    }
  }, [currentSlide, numberOfSlides, inView, isFullpageLoaded, isReenablingFp]);

  useEffect(() => {
    if (inView && savedScrollHandler.current && !customScrollerRef.current) {
      console.log("Initializing new custom scroller");
      customScrollerRef.current = new CustomScroller(savedScrollHandler.current);
    } else if (!inView && customScrollerRef.current) {
      console.log("Removing custom scroller");
      customScrollerRef.current.removeScrollTracker();
      // free up memory
      customScrollerRef.current = null;
    }
  }, [inView]);

  // On unmount always remove custom scroller
  useEffect(() => {
    return () => {
      if (customScrollerRef.current) {
        console.log("Removing custom scroller");
        customScrollerRef.current.removeScrollTracker();
        // free up memory
        customScrollerRef.current = null;
      }
    };
  }, []);
}
