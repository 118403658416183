import { css } from "styled-components";
import fluidType from "~/utils/fluidType";
import { breakpoints } from "./media";

// Variables
export const maxContentWidth = "1200px";
export const scrollableSectionPaddingBottom = "4rem";
export const fixedHeaderPadding = "8rem";
export const gridGap = "1.4375rem";

// Fluid Mixins
export const gridContentPaddingMixinHorizontal = css`
  ${fluidType(breakpoints.minPaddingLock, 16, breakpoints.maxPaddingLock, 55, "padding-right")}
  ${fluidType(breakpoints.minPaddingLock, 16, breakpoints.maxPaddingLock, 55, "padding-left")}
`;

export const gridContentPaddingMixinVertical = css`
  ${fluidType(breakpoints.minPaddingLock, 16, breakpoints.maxPaddingLock, 35, "padding-top")}
  ${fluidType(breakpoints.minPaddingLock, 16, breakpoints.maxPaddingLock, 35, "padding-bottom")}
`;

// Mixins
export const staticCenteredBodyMixin = css`
  margin: 0 auto;
  max-width: ${maxContentWidth};
  box-sizing: border-box;
  ${gridContentPaddingMixinHorizontal}
`;

export const absoluteCenteredBodyMixin = css`
  left: 50%;
  transform: translateX(-50%);
  max-width: ${maxContentWidth};
  box-sizing: border-box;
  ${gridContentPaddingMixinHorizontal}
`;

export default {
  gridGap,
  /* content starts below fixed header if not centered */
  fixedHeaderPadding,
  scrollableSectionPaddingBottom,
  maxContentWidth,
  mixins: {
    centeredBody: {
      static: staticCenteredBodyMixin,
      absolute: absoluteCenteredBodyMixin,
    },
    bodyPadding: {
      horizontal: gridContentPaddingMixinHorizontal,
      vertical: gridContentPaddingMixinVertical,
    },
  },
};
