import styled, { css } from "styled-components";
import BaseGrid from "~/components/GridLayout";
import SquareGrid from "~/components/SquareGrid";
import TiltCard from "~/components/TiltCard";

export const Container = styled(BaseGrid)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    grid-row-gap: 2rem;
    padding-top: ${theme.dimensions.fixedHeaderPadding};
    padding-bottom: ${theme.dimensions.fixedHeaderPadding};
    box-sizing: border-box;
    grid-auto-rows: auto 1fr auto;

    h5 {
      grid-row: 1 / span 1;
      grid-column: 1 / span 12;
      margin: 0 0 1rem 0;
    }

    h5,
    p {
      color: ${theme.palette.onBackground.shade1};

      em {
        color: ${theme.palette.onBackground.main};
      }
    }
  `}
`;

export const PreviewCard = styled(TiltCard)`
  grid-row: 2 / span 2;
  grid-column: 1 / span 3;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const TextSection = styled.div`
  ${({ theme }) => css`
    grid-column: 5 / span 7;
    grid-row: 2 / span 1;
    align-self: center;

    h3 {
      color: ${theme.palette.onBackground.main};
      margin: 0 0 1.45em 0;
    }

    p {
      /* line-height = 1.75 -> 3 lines height */
      height: calc(3 * 1.75 * 1em);
    }

    @media (max-width: 1024px) {
      grid-column: 5 / span 8;
    }

    @media (max-width: 900px) {
      grid-column: 1 / span 12;
      p {
        /* line-height = 1.75 -> 4 lines height */
        height: calc(4 * 1.75 * 1em);
      }
    }
  `}
`;

export const StyledSquareGrid = styled(SquareGrid)`
  grid-column: 5 / span 7;
  grid-row: 3 / span 1;

  @media (max-width: 1024px) {
    grid-column: 5 / span 8;
  }

  @media (max-width: 900px) {
    grid-column: 1 / span 12;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
