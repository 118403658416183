import React, { ReactNode, useEffect, useState } from "react";
import Header from "~/components/Header";
import { WindowLocation } from "@reach/router";
import FollowingCursor, { CursorContextProvider } from "~/components/FollowingCursor";
import PageTransitionWrapper from "~/components/PageTransitionWrapper";
import { MENU_MODAL_ID } from "../globals";

interface LayoutProps {
  children: ReactNode;
  location: WindowLocation;
}

export default function Layout({ children, location }: LayoutProps) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function checkTouchscreen() {
      try {
        document.createEvent("TouchEvent");
        return true;
      } catch (e) {
        return false;
      }
    }
    setIsMobile(checkTouchscreen());
  }, []);

  const isOnLandingPage = location.pathname === "/";

  return (
    <CursorContextProvider>
      {!isMobile && <FollowingCursor />}
      <Header isSticky={!isOnLandingPage} />
      <PageTransitionWrapper location={location}>{children}</PageTransitionWrapper>
      {/* usePortal renders here */}
      <div id={MENU_MODAL_ID} />
    </CursorContextProvider>
  );
}
