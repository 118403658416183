import { Children, cloneElement, ReactElement, useCallback, useEffect } from "react";
import { useCursorContext } from "../FollowingCursor";

interface ArrowCursorLinkProps {
  children: ReactElement;
}

export default function ArrowCursorLink({ children, ...props }: ArrowCursorLinkProps) {
  const { setIsOverLink } = useCursorContext();
  useEffect(() => {
    return () => {
      setIsOverLink(false);
    };
  }, []);
  const handleMouseLeave = useCallback(() => {
    setIsOverLink(false);
  }, [setIsOverLink]);
  const handleMouseOver = useCallback(() => {
    setIsOverLink(true);
  }, [setIsOverLink]);
  if (!Children.only(children)) {
    throw new Error("Expected only one child for Arrow Cursor Link");
  }
  return cloneElement(children, {
    onMouseOver: handleMouseOver,
    onMouseLeave: handleMouseLeave,
    ...props,
  });
}
