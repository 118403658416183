import styled, { css } from "styled-components";

const Chip = styled.li`
  ${({ theme }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${theme.palette.card.opaque};
    border-radius: 1.5em;
    color: ${theme.palette.onBackground.card.opaque};
    padding: 1em;
    line-height: 1;
    box-shadow: ${theme.shadows.sm};
    font-size: 0.875rem;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-weight: 600;
  `}
`;

export default Chip;
