import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import useTechnologiesQuery from "~/queries/useTechnologiesQuery";
import { Technology } from "~/types/Technology";
import OverflownHeadline from "~/components/OverflownHeadline";
import { useScrollContext } from "~/contexts/ScrollContext";
import Icon from "~/components/Icon";
import { SquareGridItem } from "~/components/SquareGrid";
import { Container, PreviewCard, TextSection, StyledSquareGrid } from "./style";
import { SectionRoute } from "~/types/Section";

export default function Technologies({ scrollIndex: sectionScrollIndex }: SectionRoute) {
  const {
    allTechnology: { nodes },
  } = useTechnologiesQuery();
  const technologies: Technology[] = nodes;
  const [selectedTech, setSelectedTech] = useState(technologies[0]);
  const { isFullpageLoaded, scrollIndex } = useScrollContext();
  const inView = isFullpageLoaded && scrollIndex === sectionScrollIndex;

  return (
    <AnimatePresence>
      <Container>
        {inView && (
          <OverflownHeadline key="head" as="h5" highlighted={["technologies"]}>
            My favorite technologies include
          </OverflownHeadline>
        )}
        <PreviewCard icon={selectedTech.logo!} />
        <TextSection>
          <h3>{selectedTech.name}</h3>
          <p>{selectedTech.description}</p>
        </TextSection>
        <StyledSquareGrid>
          {technologies
            .filter(t => t.isFavorite)
            .map(({ name, logo }, index) => (
              <SquareGridItem
                key={name}
                onClick={() => setSelectedTech(technologies[index])}
                active={selectedTech.name === name}>
                <Icon icon={logo!} />
              </SquareGridItem>
            ))}
        </StyledSquareGrid>
      </Container>
    </AnimatePresence>
  );
}
