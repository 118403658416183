import React from "react";
import styled, { css, keyframes } from "styled-components";
import { Link as GatsbyLink } from "gatsby";

const animOutText = keyframes`
  0% {
    transform: translateZ(0); 
  }
  100% {
    transform: translate3d(-100%, 0, 0); 
  }
`;

const animOutLayer = keyframes`
  0% {
    transform: translateZ(0); }
  100% {
    transform: translate3d(100%, 0, 0);
  }
`;

const animInLayer = keyframes`
  0% {
    transform: translate3d(-100%, 0, 0); }
  100% {
    transform: translateZ(0); }
`;

const animInText = keyframes`
  0% {
    transform: translate3d(100%, 0, 0); }
  100% {
    transform: translateZ(0); 
  }
`;

const linkContainerMixin = css`
  ${({ theme }) => css`
    height: inherit;
    color: ${theme.palette.onBackground.shade2};
    text-decoration: none;
    position: relative;
    display: inline-block;
    outline-color: ${theme.palette.onBackground.main};
    font-weight: 400;

    &:hover {
      & > span {
        animation: ${animInLayer} 0.3s ease forwards;
        &:before {
          animation: ${animInText} 0.3s ease forwards;
        }
      }
    }
  `}
`;

const PlainLinkContainer = styled.a`
  ${linkContainerMixin}
`;

const GatsbyLinkContainer = styled(GatsbyLink)`
  ${linkContainerMixin}
`;

const TranslatedMask = styled.span<{ hoverColor?: string }>`
  ${({ theme, hoverColor }) => css`
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: inherit;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(-100%, 0, 0);
    display: block;
    width: 100%;
    animation: ${animOutLayer} 0.3s ease-out;

    &:before {
      content: attr(data-text);
      width: 100%;
      display: block;
      backface-visibility: hidden;
      transform: translate3d(100%, 0, 0);
      color: ${hoverColor || theme.palette.onBackground.main};
      animation: ${animOutText} 0.3s ease-out;
    }
  `}
`;

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: string;
  href?: string;
  to?: string;
  hoverColor?: string;
}

export default function Link({ children, to, href, hoverColor, ...props }: LinkProps) {
  let linksTo;
  let Container: any;
  if (!!to) {
    Container = GatsbyLinkContainer;
    linksTo = { to };
  } else {
    Container = PlainLinkContainer;
    linksTo = { href };
  }
  return (
    <Container {...linksTo} {...props}>
      {children}
      <TranslatedMask hoverColor={hoverColor} data-text={children} />
    </Container>
  );
}
